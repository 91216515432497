<template>
	
	<div class="cont" id="p22" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0; background: none; z-index: 9999;">
		<div class="popup-list" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0;">
			<div class="popup type02" style="width: 650px; margin: 10% auto; transform: none; overflow: hidden; position: relative !important; z-index: 9999;">
			
				<div class="sub-title">
				{{ program.name }}
				</div>

				<div class="c-list">
					<div class="c01">
						<div class="s01">
							<div class="s-item"> 
								<div class="s-body">
									<div class="s-content">
										<div class="si-title">보증 보험</div>
										<div class="si-desc">
											{{ item.insuranceCoverageAmount | makeComma }}
										</div>
									</div>
									<div class="s-content">
										<div class="si-title">건별 한도</div>
										<div class="si-desc">
											<input class="w100" type="text" placeholder="건별 한도를 입력하세요"
												v-model="limit_item.oneTimeLimit"
											/>
										</div>
									</div>
									<div class="s-content">
										<div class="si-title">월 한도</div>
										<div class="si-desc">
											<input class="w100" type="text" placeholder="건별 한도를 입력하세요"
												v-model="limit_item.monthLimit"
											/>
										</div>
									</div>
									<div class="s-content" style="border: none !important;">
										<div class="si-title">연 한도</div>
										<div class="si-desc">
											<input class="w100" type="text" placeholder="건별 한도를 입력하세요"
												v-model="limit_item.yearLimit"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="s02" style="border: none !important;">
							<div class="cont-btn">
								<a @click="save"><div class="btn-submit">수정</div></a>
								<a @click="close"><div class="btn-cancel">취소</div></a>
							</div>
						</div>
					</div>
				</div>
            </div>
		</div>
	</div>

</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	
	export default{
		name: 'AgentPopupItem'
		,props: ['item']
		,data: function(){
			return {
				program: {
					name: '가맹점 결제한도 상향 신청'
				}
				,limit_item: {
				}
			}
		}
		,methods: {
			save: async function(){
				try{
					const result = await Axios({
						method: 'post'
						,url: '/merchants/limit'
						,data: this.limit_item
						,authorize: true
					})
					
					if(result.success){
						this.$emit('setNotify', { type: 'success', message: '정상적으로 처리되었습니다'})
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E.status})
				}finally{
					this.$emit('setOverlay', false)
				}	
			}
			,close: function(){
				this.$emit('setOverlay', false)
			}
		}
		,created: function(){
			this.limit_item.oneTimeLimit = this.item.oneTimeLimit
			this.limit_item.monthLimit = this.item.monthLimit
			this.limit_item.yearLimit = this.item.yearLimit
			this.limit_item.pgMerchNo = this.item.pgMerchNo
		}
	}
</script>