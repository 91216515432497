<template>
	<div class="cont" id="p13">
		<div class="page-title"> {{ program.name }} </div>

		<div class="c01">
			<div class="s01">
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">계정정보</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">아이디</div>
							<div class="si-desc text-right"> {{ item.basic.userId }} </div>
						</div>
						<div class="s-content">
							<div class="si-title">비밀번호</div>
							<div class="si-desc text-right">
							<a @click="viewPassword">
								<div class="s-link" style="color: green;  cursor: pointer;">임시 비밀번호 발급<i class="xi-external-link"></i></div>
							</a>
						</div>
						</div>
						<div class="s-content none">
							<div class="si-title">연락처</div>
							<div class="si-desc">{{ item.basic.userPhoneNumber }}</div>
						</div>
						<div class="s-content none"
						>
							<div class="si-title">이메일</div>
							<div class="si-desc">{{ item.basic.userPhoneNumber }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">가입일</div>
							<div class="si-desc text-right">{{ item.basic.joinDate | transDate }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">계정상태</div>
							<div class="si-desc text-right">
								<a @click="viewAccount">
									<div class="s-link" style="color: red;  cursor: pointer;">계정 상태 변경<i class="xi-external-link"></i></div>
								</a>
								<br/>
								{{ statusName }}
							</div>
						</div>
					</div>
				</div>
				<div class="s-item t-item">
					<div class="s-header">
						<div class="s-title">이용 서비스</div>
					</div>
					<div class="s-body">
						<div class="table type03">
							<div class="item-header">
								<div class="item">
									<div class="td">서비스</div>
									<div class="td">공급가</div>
									<div class="td" 
										v-if="user.basic.level < 2">대리점 마진</div>
									<div class="td"
										v-if="user.basic.level < 3">리셀러 마진</div>
									<div class="td">딜러 마진</div>
									<div class="td">최종 수수료</div>
								</div>
							</div>
							<div class="item-list"
								v-for="(service) in serviceType"
								:key="service.code"
							>
								<div class="item">
									<div class="td">
										<div class="cont-check">
											<input type="checkbox" :id="'ck01_' + service.code"
												v-if="item.directFlag == 'Y'"
												v-model="service.use"
												:value="service.code"
											>
											<label :for="'ck01_' + service.code"><span>{{ service.codeName }}</span></label>
										</div>
									</div>
									<div class="td">{{ service.item.supplyCommission }}%</div>
									<div class="td">
										<input type="text"
											v-if="item.directFlag == 'Y'"
											v-model="service.marginRate"
										>
									</div>
									<div class="td"
										v-if="user.basic.level < 2">{{ service.item.resalesMarginRate }}%</div>
									<div class="td"
										v-if="user.basic.level < 3">{{ service.item.dealerMarginRate }}%</div>
									<div class="td">{{ service.item.merchantCommission }}%</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">보증보험</div>
						<span class="sp" style="font-size: 0.8em; color: red;">1년 단위 갱신 계약</span>
					</div>
					<div class="s-body">
						<div class="s-content none">
							<div class="si-title">계약기간</div>
							<div class="si-desc text-right"></div>
						</div>
						<div class="s-content">
							<div class="si-title">보증보험</div>
							<div class="si-desc text-right">{{ item.insurance.insuranceCoverageAmount | makeComma }}원</div>
						</div>
						<div class="s-content">
							<div class="si-title">건별 한도</div>
							<div class="si-desc text-right">{{ item.insurance.oneTimeLimit | makeComma  }}원</div>
						</div>
						<div class="s-content">
							<div class="si-title">월 한도</div>
							<div class="si-desc text-right">{{ item.insurance.monthLimit | makeComma  }}원</div>
						</div>
						<div class="s-content">
							<div class="si-title">년 한도</div>
							<div class="si-desc text-right">{{ item.insurance.yearLimit | makeComma  }}원</div>
						</div>
						<div class="s-content">
							<div class="si-title">한도 상향</div>
							<div class="si-desc text-right">
								<a @click="viewLimit">
									<div class="s-link" style="color: green; cursor: pointer;">결제한도 상향 신청<i class="xi-external-link"></i></div>
								</a>
							</div>
						</div>
					</div>
				</div>
				
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">가맹점 정보</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">상호명(법인명)</div>
							<div class="si-desc">{{ item.company.businessName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">사업자등록번호</div>
							<div class="si-desc">{{ item.company.businessNumber }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">대표 번호</div>
							<div class="si-desc">{{ item.company.phoneNumber | makeTell}}</div>
						</div>
						<div class="s-content">
							<div class="si-title">업태/업종</div>
							<div class="si-desc">{{ item.company.businessTypeCate }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">사업장 주소</div>
							<div class="si-desc">
								{{ item.company.companyZipCode }} 
								{{ item.company.companyAddressMain }} 
							</div>
						</div>
						<div class="s-content">
							<div class="si-title">상세주소</div>
							<div class="si-desc">
								{{ item.company.companyAddressDetail }}
							</div>
						</div>
						<div class="s-content">
							<div class="si-title">서비스 구분</div>
							<div class="si-desc">{{ item.company.servicesTypeName }}</div>
						</div>
					</div>
				</div>
				
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">대표자 정보</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">대표자 성명</div>
							<div class="si-desc">{{ item.company.ceoName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">대표자 연락처</div>
							<div class="si-desc">{{ item.company.ceoMobilPhoneNumber | makeTell }}</div>
						</div>
						<div class="s-content input">
							<div class="si-title">관리 담당자</div>
							<div class="si-desc">
								<input type="text" placeholder="담당자 이름을 입력하세요"
									v-model="item.manger.managerName"
								>
							</div>
						</div>
						<div class="s-content input">
							<div class="si-title">담당자 연락처</div>
							<div class="si-desc">
								<input type="text" placeholder="담당자 연락처를 입력하세요"
									v-model="item.manger.managerMobilPhoneNumber"
								>
							</div>
						</div>
						<div class="s-content input">
							<div class="si-title">담당자 이메일</div>
							<div class="si-desc">
								<input type="text" placeholder="담당자 이메일을 입력하세요"
									v-model="item.manger.managerEmail"
								>
							</div>
						</div>
					</div>
				</div>
				
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">대금 정산</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">정산주기</div>
							<div class="si-desc">{{ item.settlement.settlementCycleName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">정산은행</div>
							<div class="si-desc">{{ item.settlement.bankCodeName }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">계좌번호</div>
							<div class="si-desc">{{ item.settlement.bankAccountNumber }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">예금주</div>
							<div class="si-desc">{{ item.settlement.bankAccountHolder }}</div>
						</div>
					</div>
				</div>
				
				<div class="s-item none">
					<div class="s-header">
						<div class="s-title">담당자</div>
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">담당자</div>
							<div class="si-desc">
								<input type="text" placeholder="담당자 이름을 입력하세요"
									v-model="item.manger.managerName"
								>
								
							</div>
						</div>
						<div class="s-content">
							<div class="si-title">핸드폰</div>
							<div class="si-desc">
								<input type="text" placeholder="담당자 연락처를 입력하세요"
									v-model="item.manger.managerMobilPhoneNumber"
								>
							</div>
						</div>
						<div class="s-content">
							<div class="si-title">이메일</div>
							<div class="si-desc">
								<input type="text" placeholder="담당자 이메일을 입력하세요"
									v-model="item.manger.managerMobilPhoneNumber"
								>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="s02 none">
				<div class="s-header">
					<div class="s-title">이용 서비스</div>
				</div>
				<div class="s-body">
					<div class="table type03">
						<div class="item-header">
							<div class="item">
								<div class="td">서비스</div>
								<div class="td">공급가</div>
								<div class="td">대리점 마진</div>
								<div class="td">리셀러 마진</div>
								<div class="td">딜러 마진</div>
								<div class="td">최종 수수료</div>
							</div>
						</div>
						<div class="item-list">
							<div class="item">
								<div class="td">
									<div class="cont-check">
										<input type="checkbox" id="ck01">
										<label for="ck01"><span>인증(온라인)</span></label>
									</div>
								</div>
								<div class="td">2.5%</div>
								<div class="td">
									<input type="text" placeholder="2%">
								</div>
								<div class="td">2%</div>
								<div class="td">2%</div>
								<div class="td">6.5%</div>
							</div>

							<div class="item">
								<div class="td">
									<div class="cont-check">
										<input type="checkbox" id="ck02"
										>
										<label for="ck02"><span>비인증(수기)</span></label>
									</div>
								</div>
								<div class="td">3%</div>
								<div class="td">
									<input type="text" placeholder="2%">
								</div>
								<div class="td">2%</div>
								<div class="td">2%</div>
								<div class="td">7%</div>
							</div>

							<div class="item">
								<div class="td">
									<div class="cont-check">
										<input type="checkbox" id="ck03">
										<label for="ck03"><span>OFF PG(단말기)</span></label>
									</div>
								</div>
								<div class="td">4%</div>
								<div class="td">
									<input class="none" type="text" placeholder="2%"> <!-- 숨김 시 class "none" -->
								</div>
								<div class="td"></div>
								<div class="td"></div>
								<div class="td">4%</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="s03 none">
				<div class="s-item_list">
					<div class="s-item">
						<div class="s-header">
							<div class="s-title">가맹점 정보</div>
						</div>
						<div class="s-body">
							<div class="s-content">
								<div class="si-title">대표자 성명</div>
								<div class="si-desc">{{ item.company.ceoName }}</div>
							</div>
							<div class="s-content">
								<div class="si-title">대표자 연락처</div>
								<div class="si-desc">{{ item.company.ceoMobilPhoneNumber }}</div>
							</div>
							<div class="s-content">
								<div class="si-title">대표 전화</div>
								<div class="si-desc">{{ item.company.ceoName }}</div>
							</div>
						</div>
					</div>
					<div class="s-item">
						<div class="s-header">
							<div class="s-title" style="visibility: hidden;">헤더</div>
						</div>
						<div class="s-body">
							<div class="s-content">
								<div class="si-title">사업자</div>
								<div class="si-desc">{{ item.company.businessName }}</div>
							</div>
							<div class="s-content">
								<div class="si-title">상호명(법인명)</div>
								<div class="si-desc">{{ item.company.businessName }}</div>
							</div>
							<div class="s-content">
								<div class="si-title">사업자등록번호</div>
								<div class="si-desc">{{ item.company.businessName }}</div>
							</div>
							<div class="s-content">
								<div class="si-title">업태/업종</div>
								<div class="si-desc">{{ item.company.businessName }} / {{ item.company.businessName }}</div>
							</div>
							<div class="s-content">
								<div class="si-title">서비스 구분</div>
								<div class="si-desc">{{ item.company.servicesTypeName }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="s04">
				<a @click="$router.go(-1)"><div class="btn-back">목록</div></a>
				<a @click="save"><div class="btn-save">저장</div></a>
			</div>
		</div>
		
		<FranchiseePopupPassword 
			v-if="showPopup.password"
			:item="item"
			
			@setOverlay="setOverlay"
			@setNotify="setNotify"
		/>
		
		<FranchiseePopupAccount
			v-if="showPopup.account"
			:item="itemStatus"

			@setOverlay="setOverlay"
			@setNotify="setNotify"
		/>
		
		<FranchiseePopupLimitRe
			v-if="showPopup.limit"
			:item="item.insurance"

			@setOverlay="setOverlay"
			@setNotify="setNotify"
		/>
		
	</div>
</template>

<script>
	import { Axios } from '@/resources/axios/axios'
	import { filter } from '@/resources/filter/filter'
	
	import FranchiseePopupPassword from '@/view/Franchisee/FranchiseePopupPassword'
	import FranchiseePopupAccount from '@/view/Franchisee/FranchiseePopupAccount'
	import FranchiseePopupLimitRe from '@/view/Franchisee/FranchiseePopupLimitRe'
	
	export default{
		name: "FranchiseeItem"
		,props: ['user', 'codes']
		,components: { FranchiseePopupPassword, FranchiseePopupAccount, FranchiseePopupLimitRe }
		,data: function(){
			return {
				program: {
					name: '가맹점 상세 정보'
				}
				,item: {
					basic: {}
					,manger: {}
					,settlement: {}
					,insurance: {}
					,company: {}
					,paymentItems: this.codes.serviceType
					,serviceType: []
				}
				,itemStatus: {
				}
				,pgMerchNo: this.$route.params.pgMerchNo
				,showPopup: {
					password: false
					,account: false
					,limit: false
				}
			}
		}
		,computed: {
			serviceType: function(){
				
				const self = this
				
				return this.codes.serviceType.filter(function(item){
					
					let index = self.item.paymentItems.findIndex(x => x.serviceItem === item.code)

					if(index > -1){
						item.item = self.item.paymentItems[index]
						item.use = true
						item.payChnCate = item.code
						if(self.user.basic.level == 1){
							item.marginRate = item.item.agentMarginRate
						}else if(self.user.basic.level == 2){
							item.marginRate = item.item.resalesMarginRate
						}else{
							item.marginRate = item.item.dealerMarginRate
						}
					}else{
						if(self.user != 'undefined'){
							if(self.user.basic.level == 1){
								item.item = {
									agentMarginRate: "0"
									,dealerMarginRate: "0"
									,merchantCommission: "0"
									,resalesMarginRate: "0"
									,supplyCommission: "0"
								}
							}else if(self.user.basic.level == 2){
								item.item = {
									dealerMarginRate: "0"
									,merchantCommission: "0"
									,resalesMarginRate: "0"
									,supplyCommission: "0"
								}
							}else{
								item.item = {
									dealerMarginRate: "0"
									,merchantCommission: "0"
									,supplyCommission: "0"
								}
							}
						}
						item.use = false
						item.payChnCate = item.code
						item.marginRate = 0
					}
					
					return item
				})
			}
			,statusName: function(){
				let index = this.codes.merchants.status.findIndex(x => x.code === this.itemStatus.status)
				if(index > -1){
					return this.codes.merchants.status[index].codeName
				}else{
					return ''
				}
			}
		}
		,filter: filter
		,methods: {
			save: async function(){
				try{
					const result = await Axios({
						method: 'post'
						,url: '/merchants/me'
						,data: {
							commissions: this.directFlag == 'Y' ? this.serviceType : null
							,managerEmail: this.item.manger.managerEmail
							,managerName: this.item.manger.managerName
							,managerMobilPhoneNumber: this.item.manger.managerMobilPhoneNumber
							,pgMerchNo: this.pgMerchNo
						}
						,authorize: true
					})
					
					if(result.success){
						this.$emit('setNotify', { type: 'success', message: '정상적으로 처리되었습니다'})
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E})
				}
			}
			,getData: async function(){
				const self = this
				try{
					const result = await Axios({
						method: 'get'
						,url: '/merchants/' + self.pgMerchNo
						,authorize: true
					})
					
					if(result.success){
						this.item = result.data.content
						this.item.insurance.pgMerchNo = this.pgMerchNo
						await this.getStatus()
					}else{
						this.$router.push({ name: 'FranchiseeList'})
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					console.log(E)
					this.$router.push({ to: 'FranchiseeList'})
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,getStatus: async function(){
				const self = this
				try{
					const result = await Axios({
						method: 'get'
						,url: '/merchants/' + self.pgMerchNo + '/status'
						,authorize: true
					})
					
					if(result.success){
						this.itemStatus = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					console.log(E)
					this.$router.push({ to: 'FranchiseeList'})
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,viewPassword: function(){
				this.showPopup.password = true
				this.$emit('setOverlay', true)
			}
			,viewAccount: function(){
				this.showPopup.account = true
				this.$emit('setOverlay', true)
			}
			,setOverlay: function(type){
				this.$emit('setOverlay', type)
				if(!type){
					this.showPopup.password = false
					this.showPopup.account = false
					this.showPopup.limit = false
				}
			}
			,setNotify: function(option){
				this.$emit('setNotify', option)
			}
			,viewLimit: function(){
				this.showPopup.limit = true
				this.$emit('setOverlay', true)
			}
		}
		,created: function(){
			this.$emit('setProgram', this.program)
			this.getData()
		}
	}
</script>








